import React, { useEffect } from "react";
import { Link, graphql, navigate } from "gatsby";
import SEO from "../../components/SEO";
import Layout from "../../components/Layout";

import styled from "styled-components";
import FAQ from "../../components/FrequentlyAskedQ";
import Breadscrum from "../../components/Breadscrum";
import JumbotronV2 from "../../components/brandingV2/JumbotronV2";
import LinkButtonV2 from "../../components/brandingV2/LinkButtonV2";
import Gdpr from "../../assets/img/gdpr.svg";
import Proces1 from "../../assets/img/archiveProces1.svg";
import Proces2 from "../../assets/img/selectUser.svg";
import Proces3 from "../../assets/img/upload.svg";
import Proces4 from "../../assets/img/willProces4.svg";
import CareIcon from "../../assets/img/care.svg";
import Prepaid from "../../assets/img/prepaid.svg";
import Will from "../../assets/img/will.svg";
import Privacy from "../../assets/img/privacy.svg";
import willFeature1 from "../../assets/img/willFeature1.svg";
import willFeature2 from "../../assets/img/willFeature2.svg";
import willFeature3 from "../../assets/img/willFeature3.svg";
import willFeature4 from "../../assets/img/willFeature4.svg";
import willFeature5 from "../../assets/img/willFeature5.svg";
import willFeature6 from "../../assets/img/willFeature6.svg";
import willFinalCta from "../../assets/img/willFinalCta.svg";
// import Proces4 from "../../assets/img/willProces4.svg";
import Czechpoint from "../../assets/img/czechpoint.svg";
import RecenzeV2 from "../../components/brandingV2/RecenzeV2";
import Proces from "../../components/brandingV2/Proces";
import BlogPreview from "../../components/brandingV2/BlogPreview";
import { useMediaQuery } from "react-responsive";
import CallButtonV2 from "../../components/brandingV2/CallButtonV2";
import NonprofitSmall from "../../components/brandingV2/NonprofitSmall";
import Check from "../../assets/img/willCheck.svg";
import IsoBadge from "../../assets/img/isoBadge.svg";
import Soc2 from "../../assets/img/soc2.svg";
import FinalCta from "../../components/brandingV2/FinalCta";
import ArchiveInfo from "../../components/zavetiV2/archiveInfo";
import FeaturesV2 from "../../components/brandingV2/FeaturesV2";
import deathVerification from "../../assets/img/deathVerification.svg";


// make it shorter 




let structuredData = {
  "@context": "https://schema.org",
  "@type": "Product",
  "name": "Digitální archiv",
  "brand":"Goodbye.cz",
  "image":"https://goodbye.cz/zavet.jpg",
  "description": "Bezpečně uchováme vaše dokumenty, informace a hesla, které po vaší smrti doručíme lidem, které jste si zvolili.",
  "aggregateRating": {
    "@type":"AggregateRating",
    "bestRating": "5",
    "ratingCount": 147,
    "ratingValue": 4.93,
    "worstRating": "1"}
};

const DigiArchivInner = styled.div`
  
`;

const DigitalniArchiv = ({ data }) => {
  const isLess500 = useMediaQuery({
    query: '(max-width: 500px)'
  });

  useEffect(() => {
    navigate("https://goodbye.bepreparedapp.com/");
  }, []);

  return (
    <Layout
      customLoginLink={"https://forms.gle/mA2FQZbTcumKpAMF8"}
      customCtaText="Odejít"
      customCtaLink={"/digitalni-archiv/"}
      customLoginText={"Založit účet"}
    >
      <SEO
        title="Digitální archiv: Aplikace | Goodbye.cz"
        customTitle
        description="Uchováme vaše dokumenty, informace a hesla, které po vaší smrti doručíme lidem, které si zvolíte. Vojenská úroveň zabezpečení dat, podpora CzechPoint."
        image="/archivOg.png"
        noindex={true}
      />

      <iframe src="https://goodbye.bepreparedapp.com/" style={{width: "100%", height: "100vh", border: "none", minHeight: "500px"}}></iframe>

      {/* https://goodbye.bepreparedapp.com/ */}

      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}/>

      <DigiArchivInner>
        
      </DigiArchivInner>
    </Layout>
  );
}

export default DigitalniArchiv;


